// extracted by mini-css-extract-plugin
export var rolesTabWrapper = "UserPermissionsTab-module--rolesTabWrapper--sKwZl";
export var searchBoxWrapper = "UserPermissionsTab-module--searchBoxWrapper--2HzFl";
export var actionWrapper = "UserPermissionsTab-module--actionWrapper--1-uOR";
export var width39 = "UserPermissionsTab-module--width39--1sWNz";
export var rowFlex = "UserPermissionsTab-module--rowFlex--2kZ4y";
export var searchSelectWrapper = "UserPermissionsTab-module--searchSelectWrapper--3r7Bj";
export var assignRole = "UserPermissionsTab-module--assignRole--qh4iG";
export var groupBtn = "UserPermissionsTab-module--groupBtn--3ZaqP";
export var antSelectSelector = "UserPermissionsTab-module--ant-select-selector--reQv0";
export var editPermission = "UserPermissionsTab-module--editPermission--2zMJD";
export var marginLeft16 = "UserPermissionsTab-module--marginLeft16--1iaYg";
export var adminAcessButton = "UserPermissionsTab-module--adminAcessButton--25XdZ";
export var table = "UserPermissionsTab-module--table--2Uusp";
export var activityLogs = "UserPermissionsTab-module--activityLogs--1tbw0";
export var remove = "UserPermissionsTab-module--remove--_ejn3";
export var moreOptions = "UserPermissionsTab-module--moreOptions--19Coq";