import React from 'react';
import PropTypes from 'prop-types';
import Authenticate from '../../../components/Identity/Authenticate';
import Layout from '../../../components/Admin/Layout';
import UserPermissionsTabView from '../../../components/Admin/Accounts/UserPermissionsTabView';
import * as styles from '../../../styles/admin/Account.module.scss';
import accountTabs from '../../../accountTabs';
import { AdminProvider } from '../../../context/Admin/AdminContext';

function UserPermissions(props) {
  const {
    identityProviderClient, location,
  } = props;
  return (
    <AdminProvider>
      <div className={styles.accountPageWrapper}>
        <Layout
          title="Manage Accounts"
          Component={UserPermissionsTabView}
          tabs={accountTabs}
          activeTab="Learner Permissions"
          identityProviderClient={identityProviderClient}
          location={location}
          moreInfo="<p className='text'>In this section, Admins and Managers can send additional invites, edit learner & group permissions</p>"
        />
      </div>
    </AdminProvider>
  );
}
UserPermissions.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Authenticate(UserPermissions);
