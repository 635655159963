import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  editRole,
  addUserToCognitoGroup,
  removeUserFromCognitoGroup,
} from './Services/UserTabService';
import EditRole from './Popups/EditRole';
import ConfirmPopup from './Popups/ConfirmPopup';
import MoreInfo from '../MoreInfo';
import * as invitesTabStyles from '../../../styles/admin/InviteTab.module.scss';
import * as rolesTabStyles from '../../../styles/admin/UserPermissionsTab.module.scss';
import TableComponent from '../../Shared/Table/TableComponent';
import { getCurrentUser } from '../../Shared/Services/AuthService';
import AdminContext, { MoreInfoStatus } from '../../../context/Admin/AdminContext';
import { adminUpdateUser, getGroupsByEnterpriseID, getUsersByEnterpriseID } from '../../GraphQL/Services';
import { isBDMUser, toaster } from '../../../services/utils';
import { Role } from '../../../models';

function UserPermissionsTabView(props) {
  const { identityProviderClient, location } = props;
  const { showMoreInfo, setShowMoreInfo } = useContext(AdminContext);
  const [tableData, setTableData] = useState([]);
  const [signedInUser, setSignedInUser] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [editRoleModalVisible, setEditRoleModalVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [groupList, setGroupList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { companyInfo, mapOfGroupsList } = useContext(AdminContext);

  const listUsers = async () => {
    if (companyInfo?.id) {
      const allUsersList = await getUsersByEnterpriseID(companyInfo.id);

      const totalUsers = allUsersList.filter((user) => !isBDMUser(user.email)
        && !user?.deletedAt && user?.roles?.length && !user.roles.includes('OWNER'))
        .map((currentUser) => {
          // eslint-disable-next-line prefer-destructuring, no-param-reassign
          currentUser.role = currentUser.roles.includes('ADMIN') ? 'Admin' : 'Manager';
          return currentUser;
        });
      setTableData(totalUsers);
    }
  };

  const listGroupHandler = async () => {
    if (companyInfo?.id) {
      const groupData = await getGroupsByEnterpriseID(companyInfo.id);
      if (groupData) {
        setGroupList(groupData);
      }
    }
  };

  const showAssignModal = (title, record) => {
    setPopupTitle(title);
    setSelectedUser(record);
    setEditRoleModalVisible(true);
  };

  const toggleAdminAccess = async (record) => {
    setTableLoader(true);
    let roles = [...record?.roles];
    try {
      if (!record?.roles?.includes('ADMIN')) {
        // add to admin to cognito group
        await addUserToCognitoGroup(record.email, 'admin', identityProviderClient);
        roles = ['ADMIN'];
      } else {
        // remove from admin cognito group
        const managerGroupList = [];

        mapOfGroupsList?.forEach((value) => {
          value?.memberInfo?.forEach((groupMember) => {
            if (groupMember?.memberID === record?.id) {
              if (!groupMember?.deletedAt && groupMember?.isManager) {
                managerGroupList.push(value?.groupName);
              }
            }
          });
        });

        await removeUserFromCognitoGroup(record.email, 'admin', identityProviderClient);

        if (managerGroupList?.length) {
          roles = ['MANAGER'];
        } else {
          roles = [];
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      await adminUpdateUser(record?.id, [...roles], record?._version);
      await listUsers();
    } catch (error) {
      toaster('Something went wrong', 'error');
    }

    setTableLoader(false);
  };

  const confirmAdminToggle = (record) => {
    setSelectedUser(record);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 130,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 130,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: 130,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        (record?.email !== signedInUser?.attributes.email)
        && (
        <div className={rolesTabStyles.actionWrapper}>
          <button
            id="admin-accounts-learner-permissions-edit-permissions"
            type="button"
            className={rolesTabStyles.editPermission}
            onClick={() => showAssignModal('Edit Role', record)}
          >
            Edit permissions
          </button>
        </div>
        )
      ),
      width: 140,
    },
    {
      title: 'Admin access',
      dataIndex: 'admin',
      render: (_, record) => (
        record?.email !== signedInUser?.attributes.email
          && (
          <button
            id={record?.role === 'Admin' ? 'admin-accounts-learner-permissions-revoke-access' : 'admin-accounts-learner-permissions-grant-access'}
            type="button"
            className={rolesTabStyles.adminAcessButton}
            onClick={() => confirmAdminToggle(record)}
          >
            {record?.role === 'Admin' ? 'Revoke Access' : 'Grant Access'}
          </button>
          )
      ),
      width: 140,
    },
  ];

  const prepareHeaderForCSV = () => {
    const headers = [];
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (!['actions', 'admin'].includes(column.dataIndex)) {
        headers.push({
          label: column.title,
          key: column.dataIndex,
        });
      }
    }
    return headers;
  };

  const filterByGroupHandler = (selectedGroups) => {
    if (selectedGroups?.length === groupList?.length) return tableData;
    const selectedGroupIds = groupList.filter((group) => selectedGroups.includes(group.groupName))
      .map((group) => group.id);

    const allSelectedGroupMembers = [];
    selectedGroupIds.forEach((groupId) => {
      const groupData = mapOfGroupsList?.get(groupId);
      const memberIds = groupData.memberInfo.filter((member) => !member?.deletedAt)
        .map((member) => member.memberID);
      allSelectedGroupMembers.push(...memberIds);
    });

    const filteredGroupMembers = [];
    tableData.forEach((member) => {
      if (
        allSelectedGroupMembers.includes(member.id)
        && [Role.ADMIN, Role.MANAGER].includes(member?.roles[0])
      ) {
        filteredGroupMembers.push(member);
      }
    });

    return filteredGroupMembers;
  };

  const closeMoreInfoBanner = () => {
    setShowMoreInfo(MoreInfoStatus.DEFAULT);
  };
  /**
   * get users from cognito pool
   */
  useEffect(async () => {
    setTableLoader(true);
    const user = await getCurrentUser();
    setSignedInUser(user);
    await listUsers();
    await listGroupHandler();
    setTableLoader(false);
  }, [companyInfo]);

  return (
    <div className={rolesTabStyles.rolesTabWrapper}>
      {showMoreInfo === MoreInfoStatus.SECONDARY
       && (
       <MoreInfo
         text="<p>Admins will be able to view course progress and edit permissions for all Learners. Managers will be able to do the same only for their respective groups. </p>"
         mainContent={false}
         hideBanner={closeMoreInfoBanner}
       />
       )}
      <p className={invitesTabStyles.title}>Managers & Admins</p>
      <TableComponent
        globalSearchPlaceholder="Search by learner name, email"
        displayColumns={columns}
        allData={tableData}
        tableLoader={tableLoader}
        headers={prepareHeaderForCSV()}
        fileName="User Permissions Report.csv"
        filters={{
          group: {
            name: 'Group',
            type: 'checkbox',
            options: groupList.map((group) => group.groupName),
            groupList,
            filterHandler: filterByGroupHandler,
          },
        }}
        location={location}
        domIDs={{
          Download: 'admin-accounts-learner-permissions-download',
        }}
      />
      <EditRole
        isModalVisible={editRoleModalVisible}
        onOk={editRole}
        onCancel={() => setEditRoleModalVisible(false)}
        title={popupTitle}
        selectedUser={selectedUser}
        identityProviderClient={identityProviderClient}
        domID="admin-accounts-learner-permissions-change-role-popup-confirm"
      />
      <ConfirmPopup
        onOk={async () => {
          setIsModalVisible(false);
          await toggleAdminAccess(selectedUser);
          setSelectedUser(null);
        }}
        onCancel={() => {
          setSelectedUser(null);
          setIsModalVisible(false);
        }}
        modalObject={
          {
            isVisible: isModalVisible,
            title: 'Admin access',
            description: `Are you sure you want to ${selectedUser?.roles?.includes('ADMIN') ? 'remove' : 'provide'} Admin access to this user? The user will ${selectedUser?.roles?.includes('ADMIN') ? 'lose' : 'get'}  access to all groups, reports and course content`,
            buttonText: !selectedUser?.roles?.includes('ADMIN') ? 'Grant Access' : 'Revoke Access',
            selectedUser,
          }
        }
        domIDs={{
          Cancel: `${!selectedUser?.roles?.includes('ADMIN') ? 'admin-accounts-learner-permissions-grant-access-popup-cancel' : 'admin-accounts-learner-permissions-revoke-access-popup-cancel'}`,
          OK: `${!selectedUser?.roles?.includes('ADMIN') ? 'admin-accounts-learner-permissions-grant-access-popup-grant-access' : 'admin-accounts-learner-permissions-revoke-access-popup-revoke-access'}`,
        }}
      />
    </div>
  );
}

UserPermissionsTabView.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UserPermissionsTabView;
